import dynamic from 'next/dynamic'
import Image from 'next/image'

import { PageContainer } from '@/components/atomic/PageContainer'
import { PageMetaData } from '@/components/atomic/PageMetaData'
import { Header } from '@/components/styleguide/Header'
import { constants } from '@/lib/constants'
import { ROUTES } from '@/lib/routes'

const AuthenticationProcess = dynamic(() => import('@/components/views/AuthenticationProcess'), {
  ssr: false,
})
export default function LoginPage() {
  return (
    <>
      <PageMetaData
        title="Login Page | The UK's Biggest Car Lease Comparison Site | LeaseLoco"
        pageTitle="Login Page | The UK's Biggest Car Lease Comparison Site | LeaseLoco"
        description="Log in to your LeaseLoco account to check in on your Garage, enquiries, rewards and update your preferences."
        pageUrl={ROUTES.loginPage.url}
      />
      <Header backgroundColor="white" />
      <PageContainer containerClass="container--auth-page">
        <AuthenticationProcess authPage />
        <Image
          className="mascot-image"
          src={`${constants.imageHost.assets}/mascot/full-body-3.png`}
          alt="Luis Loco"
          height="473"
          width="320"
        />
      </PageContainer>
    </>
  )
}
